// @flow
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState,
} from 'react';
import {
    useFavouriteHandler,
    useRecentlyAddedActions,
    useSaveToCartHandler,
} from 'hooks';
import {MATERIAL_TYPE} from 'components/customer/Materials/helper/constants';
import {compareObjects, useQueryParams} from 'shared/helpers';
import {useConfirmationDialog} from 'shared';
import {cloneDeep, merge} from 'lodash';
import {QFPContext} from 'components/customer/QFPRedux/QFPContext';
import {Page} from 'store/customer/entity/Page';
import {useBreadCrumb} from 'hooks/Product/useBreadCrumb';

type setValidationDataActionType = {
    type: string,
    key?: string,
    value?: any,
    data?: any,
};

type ProductContextInterface = {
    setValidationData?: (action: setValidationDataActionType) => void,
    setMaterialOptions?: (data: object | Function) => void,
};

export const ProductContext: React$Context<ProductContextInterface> =
    createContext({});

/**
 *
 * @param {Page} page
 *
 * @export
 * @return {*}
 */
export function useProductContext<T>(page = Page.PRODUCT): T {
    if (page && page == Page.QFP) {
        return useContext(QFPContext);
    }

    return useContext<T>(ProductContext);
}

type ProductProviderType = {
    children: any,
    index?: number,
    miniBrowser?: boolean,
};

export const ProductProvider = ({
    children,
    index = -1,
    miniBrowser = false,
}) => {
    const {
        breadcrumbs,
        addBreadCrumb,
        removeBreadCrumb,
        popBreadCrumb,
        resetBreadCrumb,
    } = useBreadCrumb();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    const {AddToFavourite} = useFavouriteHandler();
    const {AddToCart} = useSaveToCartHandler();
    const {addRecentItem} = useRecentlyAddedActions();
    const {product} = useQueryParams();

    const drawerRunnerModifiersStore = useRef([]);
    const productDataStore = useRef({});
    const productReference = useRef({});
    const initialValuesReference = useRef({});
    const defaultValuesReference = useRef({});
    const materialOptions = useRef({});
    const drawerRunnerInfoReference = useRef([]);
    const shelvesHeightReference = useRef({});
    const simpleShelvesReference = useRef();
    const shelvesUpdatedReference = useRef({});
    const newShelfStore = useRef({
        autoPosition: true,
        position: 0,
    });
    const forty5Fields = useRef({widthChanged: false});
    const productHasDoor = useRef({
        [MATERIAL_TYPE.EXTERIOR_COLOUR]: false,
        [MATERIAL_TYPE.CARCASE_COLOUR]: false,
    });
    const validateField = useRef(false);
    const userUpdatedRunnersManually = useRef(false);
    const formSubmitCallback = useRef(false);
    const fieldIndex = useRef(index).current;

    const [isMiniBrowser, setIsMiniBrowser] = useState(miniBrowser);
    const [search, setSearch] = useState<string>('');
    const [shelvesHeightUpdated, setShelvesHeightUpdated] = useState({});
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [formStructure, setFormStructure] = useState([]);
    const [formSubmitCheck, setFormSubmitCheck] = useState(false);
    const [doorPreviewOpacity, setDoorPreviewOpacity] = useState<number>(0.5);
    const [materialLoading, setMaterialLoading] = useState<boolean>(false);
    const [loadingRunners, setLoadingRunners] = useState<Array<boolean>>([]);
    const [materialFace, setMaterialFace] = useState<boolean>(false);
    const [roomAssitantContent, setRoomAssistantContent] = useState({});
    const [adjustableLegsQuantity, setAdjustableLegsQuantity] =
        useState<number>(4);

    const getAvailableDrawers = () => {
        if (productDataStore.current) {
            if (
                productDataStore?.current?.cabinet?.attributes
                    ?.available_drawers.length
            ) {
                return productDataStore.current.cabinet.attributes.available_drawers
                    .split(',')
                    .map((drawer) => parseInt(drawer));
            }
        }

        return [];
    };

    const getAvailableDoors = () => {
        if (productDataStore.current) {
            if (
                productDataStore?.current?.cabinet?.attributes?.available_doors
                    .length
            ) {
                return productDataStore.current.cabinet.attributes.available_doors
                    .split(',')
                    .map((door) => parseInt(door));
            }
        }

        return [];
    };

    const [shelvesPositionType, setShelvesPositionType] = useReducer(
        (state, action) => {
            const {key, value} = action.payload;

            const currentPositions = cloneDeep(state);

            currentPositions[key] = value;

            return currentPositions;
        },
        {}
    );

    const [validationData, setValidationData] = useReducer((state, action) => {
        let newState = cloneDeep(state);

        switch (action.type) {
            case 'single':
                newState[action.key] = action.value;
                break;

            case 'multiple':
                newState = merge(newState, action.data);
                break;

            case 'reset':
                newState = {};
                break;
        }

        return newState;
    }, {});

    const [productLoaderCount, dispatchLoader] = useReducer(
        (state: {count: number}, action: {type: string}): {count: number} => {
            switch (action.type) {
                case 'add':
                    return {count: state.count + 1};

                case 'minus':
                    return {count: state.count > 0 ? state.count - 1 : 0};

                default:
                    throw new Error('undefined action type');
            }
        },
        {count: 0}
    );

    const isLoading = useMemo<boolean>(
        () => productLoaderCount.count > 0,
        [productLoaderCount]
    );

    const areRunnersLoading = useCallback(() => {
        return loadingRunners.some(Boolean);
    }, [loadingRunners]);

    const startLoading = () => dispatchLoader({type: 'add'});

    const finishLoading = () => dispatchLoader({type: 'minus'});

    const addValidationData = (key, value) => {
        setValidationData({type: 'single', key, value});
    };

    const getValidationData = (key) => {
        return validationData.hasOwnProperty(key) ? validationData[key] : {};
    };

    const setProduct = (product) => {
        productReference.current = product;
    };

    const getProductInfo = (key) => {
        if (key == 'image') {
            return productReference.current['image'];
        }
        if (key == 'changedImage') {
            const image =
                productReference.current.hasOwnProperty(
                    'manufacturer_cabinet_image'
                ) &&
                productReference.current['manufacturer_cabinet_image'] != null
                    ? '/' +
                      productReference.current['manufacturer_cabinet_image']
                    : '';
            return image;
        }
        return productReference.current.hasOwnProperty(key)
            ? productReference.current[key]
            : false;
    };
    const setInitialValuesRef = (initialValues) => {
        initialValuesReference.current = initialValues;
    };

    const addInitialValue = (key, value) => {
        initialValuesReference.current[key] = value;
    };

    const getInitialValue = (key) => {
        return initialValuesReference.current.hasOwnProperty(key)
            ? initialValuesReference.current[key]
            : key;
    };

    const setMaterialOptions = (options) => {
        if (typeof options === 'function') {
            materialOptions.current = options(materialOptions.current);

            return;
        }

        materialOptions.current = {...materialOptions.current, ...options};
    };

    const getMaterialOptions = () => {
        return materialOptions.current;
    };

    const setNewShelfValue = (name, value) => {
        newShelfStore.current[name] = value;
    };

    const resetNewShelf = () => {
        newShelfStore.current = {
            autoPosition: true,
            position: 0,
        };
    };

    const setHeightReference = (key, value) => {
        const old = {...shelvesHeightReference.current};
        shelvesHeightReference.current[key] = value;

        if (!compareObjects(old, shelvesHeightReference.current)) {
            setShelvesHeightUpdated({});
        }
    };

    const setPositionType = (key, value) => {
        setShelvesPositionType({payload: {key, value}});
    };

    useEffect(() => {
        setValidationData({type: 'reset'});
        setDoorPreviewOpacity(0.5);
        setFormSubmitCheck(false);
        setFormStructure([]);
        materialOptions.current = {
            cabinet_ext_colour: {thickness: 16.5},
            cabinet_carc_colour: {thickness: 16.5},
        };
        drawerRunnerModifiersStore.current = [];
        validateField.current = false;
        userUpdatedRunnersManually.current = false;
        formSubmitCallback.current = false;
        productDataStore.current = {};
    }, [product]);

    return (
        <ProductContext.Provider
            value={{
                search,
                setSearch,
                AddToCart,
                AddToFavourite,
                validationData,
                addValidationData,
                setValidationData,
                getValidationData,
                productDataStore,
                setProduct,
                getProductInfo,
                setInitialValuesRef,
                getInitialValue,
                setMaterialOptions,
                getMaterialOptions,
                setPositionType,
                shelvesPositionType,
                setNewShelfValue,
                resetNewShelf,
                newShelfStore,
                shelvesHeightReference,
                setHeightReference,
                shelvesHeightUpdated,
                drawerRunnerInfoReference,
                forty5Fields,
                simpleShelvesReference,
                addRecentItem,
                addInitialValue,
                productHasDoor,
                shelvesUpdatedReference,
                showDialog,
                hideDialog,
                currentTab,
                setCurrentTab,
                formStructure,
                setFormStructure,
                validateField,
                drawerRunnerModifiersStore,
                userUpdatedRunnersManually,
                formSubmitCallback,
                formSubmitCheck,
                setFormSubmitCheck,
                breadcrumbs,
                addBreadCrumb,
                removeBreadCrumb,
                popBreadCrumb,
                resetBreadCrumb,
                doorPreviewOpacity,
                setDoorPreviewOpacity,
                defaultValuesReference,
                isLoading,
                startLoading,
                finishLoading,
                fieldIndex,
                materialLoading,
                setMaterialLoading,
                loadingRunners,
                setLoadingRunners,
                areRunnersLoading,
                getAvailableDoors,
                getAvailableDrawers,
                materialFace,
                setMaterialFace,
                roomAssitantContent,
                setRoomAssistantContent,
                setAdjustableLegsQuantity,
                adjustableLegsQuantity,
                isMiniBrowser,
                setIsMiniBrowser,
            }}>
            {children}
            {dialog}
        </ProductContext.Provider>
    );
};
